@import '~@vismaux/vud/dist/css/vud.min.css';

/*@import '~@vismaux/vud/dist/css/vud.dark.mode.min.css';*/
@import '~@vismaux/vud/dist/css/vud.light-dark.mode.min.css';

:root {
    --navigation-default-bg: linear-gradient(to right, #48237D, #8049CC);
    --anchor-focus-outline: #8049CC;
    --vertical-nav-default-item-hover-bg: #EEE7F9;
    --navigation-secondary-item-focus-border-color: #8049CC;
    --button-hover-border-color: #8049CC;
    --module-container-hover-bg-color: #EEE7F9;
    --dropdown-menu-hover-bg: #EEE7F9;
    --button-hover-bg: #EEE7F9;
    --bg-secondary: #8049CC;
    --badge-info-bg: #6431AC;
}

.container-padding {
    padding: 1.5em;
}

.cursorpointer {
    cursor: pointer;
}

.full-width {
    width: 100%;
}

@media screen and (max-width: 900px) {
    .container {
        max-width: 100%;
    }

    nav {
        display: none;
    }

    .menu-icon {
        padding: 2em;
    }

    .vismaicon.vismaicon-tools:not(.vismaicon-filled)::before {
        background-color: white;
    }

    nav.responsive {
        background-color: white;
        float: right;
        display: block;
        text-align: center;
        padding: 14px 16px;
        font-size: 17px;
    }

    .nav-list-item {
        text-decoration: none !important;
        color: black !important;
    }

    .list-wrap {
        flex-direction: column;
    }

    .btn-filter {
        display: none;
    }

    .dropdown-filter {
        position: relative;
        width: 100%;
    }

    .phone-filter-dropdown {
        right: 0;
        min-width: 20rem;
    }

    .dropdown-in-dropdown {
        display: block;
        right: 4rem;
        top: 10rem;
        padding: .3rem 1.6rem .4rem;
        min-width: 20rem;
    }

    .dropdown-in-dropdown>li {
        padding: .3rem 1.6rem .4rem !important;
    }
}

@media screen and (min-width: 900px) {
    .container {
        max-width: 80%;
    }

    .phone-tablet-view {
        display: none;
    }

    .nav-list-item {
        text-decoration: none !important;
        color: white !important;
    }

    .gap {
        gap: 6rem;
    }

    .gap-big {
        gap: 24rem;
    }

    .phone-view-filter {
        display: none;
    }
}

.modal-footer {
    margin-top: auto;
}

.text-white {
    color: white;
}

.list-search-clear {
    right: 1.2rem !important;
}

/*Fix for VUD modal and ReactModal working together*/
.ReactModal__Overlay {
    z-index: 1050;
}

/*typeahead search list*/
.rbt-menu {
    z-index: 1;
    margin-top: 3.2rem;
}

.alert-window {
    position: fixed;
    left: 50%;
    width: 50%;
    text-align: center;
    z-index: 9999999;
    margin: 0%;
}

.alert-top {
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    top: 5%;
}

.alert-bottom {
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    bottom: 5%;
}

.alert-middle {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
}

.edit-role-icon {
    float: right;
}

.role-edit>div {
    margin-bottom: 0;
}

.role-edit>div>div {
    padding-left: 0;
}

.role-edit {
    float: left;
}

.user-role-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.first-line {
    border-bottom: 5px solid #F2F2F2;
}

.btn-group-primary-last {
    border-top-right-radius: 0.8rem !important;
    border-bottom-right-radius: 0.8rem !important;
}

.pull-right {
    float: right !important;
}

.status-list-container {
    justify-content: space-around;
}

.internal-btn-grp {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.internal-btn {
    flex: 1;

}

.internal-btn>button {
    width: 100%;
}

.internal-btn-grp>div:not(:last-child) {
    margin-right: 8px !important;
}


.internal-filters {
    display: flex;
    flex-wrap: wrap;
}

.status-list-icon {
    float: right;
}

.scroll-button-container {
    position: fixed;
    right: 40px;
    bottom: 40px;
    z-index: 10;
    cursor: pointer;
    border: none !important;
    visibility: hidden;
    opacity: 0;
    transition: all 500ms;
}

.scroll-button-visible {
    visibility: visible;
    opacity: 1;
}


.label-icon {
    margin-left: 8px;
    top: 2px
}

.product-info-container {
    display: flex;
    justify-content: space-between;
}

.product-info-right {
    display: flex;
}

.product-info-right>p:not(:last-child) {
    margin-right: 20px;
}

.product-info-card-container {
    width: 30%;
    height: 200px;
    display: flex;
    justify-content: space-around;
    overflow: auto;
}

.product-info-card-wrapper {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
}

.product-info-header {
    text-align: center;
}

.product-info-description {
    text-align: center;
}

.product-info-price {
    text-align: center;
}

.product-info-website {
    display: block;
    text-align: center;
    text-decoration: none;
}

.product-info-website:hover {
    display: block;
    text-align: center;
    text-decoration: none;
    color: white;
}

.product-info-website-container {
    display: flex;
    justify-content: space-around;
}

.product-info-website-background {
    width: 50%;
    padding: 8px 0 8px 0;
}

.product-info-card {
    position: relative;
}

.product-price-and-website-container {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.expand-log-icon-container {
    display: flex;
    justify-content: right;
    margin-top: 50px;
    margin-right: 4px;
}

.rotated {
    transform: rotate(180deg);
}

.warning-log-icon {
    transition: all 500ms;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.save-log-button {
    float: right;
}

.save-log-button-container {
    margin-top: 30px;
    margin-bottom: 10px;
    width: 100%;
    display: inline-block;
}

.label-icon[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

.label-icon[data-title]:after {
    content: attr(data-title);
    background-color: #FFFFFF;
    color: #111;
    font-size: 100%;
    position: absolute;
    padding: 10px;
    left: 100%;
    opacity: 0;
    border: 1px solid #8a8a8a;
    z-index: 99999;
    visibility: hidden;
    width: 300px;
    border-radius: 10px;
}

.label-icon[data-title] {
    position: relative;
}

.progressContainer {
    position: fixed;
    width: 50%;
    left: 25%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 1px 1px 12px #ccc;
    z-index: 1000;
}

.comment-container {
    margin: 2rem;
    padding: 2rem;
}

.comment-container .comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

div > .menu-card:not(:last-child){
    margin-right: 62px;
}

.menu-card{
    min-height: 376px;
    width: 343px;
    align-self: stretch;
}

.menu-card-img{
    width: 100%;
    height: 100%;
}

.menu-card-img-container{
    width: 100%;
    height: 130px;
    overflow: hidden;
}

.menu-card-content{
    flex-grow: 1;
    justify-content: space-between;
}

.added-fortnox-migration-container-hidden{
    overflow: hidden;
    visibility: hidden;
}

.added-fortnox-migration-container-visible {
    max-height: 188%;
}
.expanded-ao-list .ao-list-content {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.hidden-ao-list .ao-list-content {
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.hidden-ao-list svg {
    transform: rotate(0);
    transition: all 0.3s ease-in-out;
}

.expanded-ao-list svg {
    transform: rotate(90deg);
    transition: all 0.3s ease-in-out;
}

.bg-hero-card{
    background-color: var(--hero-card-bg)
}
