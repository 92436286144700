.SMBMan {
    position: absolute;
    border: none !important;
    top: 35%;
    left: 65%;
    width: 400px;
    height: auto;
}

.SMBWoman {
    position: absolute;
    border: none !important;
    bottom: 0%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    height: auto;
}

.product-card-container {
    display: inline-block;
    transition: outline 0.1s ease-in-out;
}

.product-card-special {
    height: 512px;
    width: 238px;
    background-color: #403380;
    color: #FFFFFF;
    position: relative;
}

.product-card {
    height: 464px;
    width: 238px;
    color: #403380;
    background-color: #FFFFFF;
}

.product-card-container .price-text {
    font-size: 4.8rem !important;
}

.product-card-container ul {
    list-style: none;
    padding-left: 0;
}

.product-card-content {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    height: 100%;
}

.product-card-container ul li:before {
    content: '✓';
    padding-right: 14px;
    font-size: 10px;
    font-weight: bolder;
}

.product-card-container ul li:not(:last-child) {
    margin-bottom: 8px;
}

.product-detail-list {
    margin-left: 7px;
}

.product-detail-list li {
    font-size: 14px !important;
}

.product-card-button {
    border-color: #403380 !important;
}

.product-card-button p {
    font-weight: 600;
    color: #403380;
}

.most-bought-label {
    height: 22px;
    width: 72px;
    position: absolute;
    background-color: #FDE753;
    right: 0;
    top: 48px;
}

.most-bought-label>p {
    font-size: 10px;
    text-align: center;
    color: #403380;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    line-height: 22px;
}

.product-card-special>.product-card-content {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
}

.most-bought-label::before {
    display: block;
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    right: 72px;
    border-style: solid;
    border-width: 11px 8px 11px 0;
    border-color: transparent #FDE753 transparent transparent;
}

.product-card-container.selected {
    outline: 5px solid #F56262;
}
.period-dropdown {
    margin:auto;
    width: 120px;
}
.period-dropdown  .form-control{
    max-width: none !important;
    min-width: 0 !important;
}

.period-dropdown .form-group{
    margin-bottom: 1.6rem;
}

.period-text{
    font-size: 13px;
    margin-bottom: 1.6rem;
    text-align: center;
}