

input[type='checkbox'] {
    width: 20px;
    height: 20px;
    background-color: transparent;
    position: relative;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 2px solid #478242;
    border-radius: 2px;
    cursor: pointer;
}

    input[type='checkbox']:checked + label:before {
    background-color: #4E9C54 !important;
    border-color: #4E9C54 !important
}

input[type='checkbox']:hover {
    border-color: #4E9C54;
}
