input.form-control:hover,
textarea.form-control:hover {
    border-color: #7365B8; 
    outline: none; 
    box-shadow: none;
}

input.form-control:focus,
textarea.form-control:focus {
    border-color: #7365B8; 
    border-width: 2px;
    outline: none; 
    box-shadow: none;
}
